import React from 'react'
import loadable from '@loadable/component'

const HubspotAssessment = loadable(() => import('../common/hubspot-assessment'))
const YoutubePlayerLite = loadable(() => import('../common/youtube-player-lite'))

const PageManagedITSection = () => (
  <>
    <div className="xl:hidden w-full xl:w-1/2 mx-auto">
      <HubspotAssessment />
    </div>

    <div className="bg-white w-full border-b">
      <div className="w-11/12 max-w-screen-xl flex flex-col-reverse xl:flex-row items-center justify-center mx-auto my-12">
        <div className='w-full lg:w-1/2 p-4'>
          <h2 className='text-3xl md:text-4xl text-site-lightblue font-bold mb-6'>Managed IT Services</h2>
          <p className='text-lg text-gray-800 mb-4'>With Fully Managed IT Services, you won’t have to worry about maintaining your IT hardware, managing an on-site data center, or monitoring for potential network/computer issues that could lead to downtime and profit loss.</p>
          <p className='text-lg text-gray-800 mb-4'>IT Proactive is an outsourced IT Support firm that you can trust. We offer fully managed IT solutions that are simple, affordable and all-inclusive.</p>
          <p className='text-lg text-gray-800'>We specialize in offering enterprise level support to small and medium sized businesses.</p>
        </div>

        <div className='w-full lg:w-1/2 p-4'>
          <YoutubePlayerLite youtubeID="UBeSrNWe7BU" title="Managed IT Services" />
        </div>
      </div>
    </div>
  </>
)

export default PageManagedITSection